import { Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  ChatBubbleLeftIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { Link, Outlet } from '@remix-run/react';
import { Fragment } from 'react';
import { UserSession } from '~/services/auth/Schemas';
import { capitalizeFirstLetter, getUserInitials } from '~/utils/format';
import { Avatar, AvatarFallback } from '../ui/avatar';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { cn } from '../ui/utils';
import { UserNavigationItem } from './types';
import { Routes } from '~/constants/routes';
import { Button } from '../ui/button';

export function HeaderNavigationBar({
  pathname,
  setSidebarOpen,
  user,
  userNavigation,
  className,
}: {
  pathname: string;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user?: UserSession;
  userNavigation?: UserNavigationItem[];
  className?: string;
}) {
  return (
    <div className={cn('lg:pl-72', className)}>
      <div className="sticky top-0 z-40 flex items-center h-16 px-4 border-b shadow-sm shrink-0 gap-x-4 border-border bg-background sm:gap-x-6 sm:px-6 lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="w-6 h-6" aria-hidden="true" />
        </button>

        <div className="w-px h-6 bg-border lg:hidden" aria-hidden="true" />

        <div className="flex items-center self-stretch justify-between flex-1 gap-x-4 lg:gap-x-6">
          <p className="text-3xl font-bold">{displayPathName(pathname)}</p>
          <div />

          <div className="flex items-center gap-x-4 lg:gap-x-6">
            {user && (
              <Popover>
                <PopoverTrigger className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="w-6 h-6" aria-hidden="true" />
                </PopoverTrigger>
                <PopoverContent className="w-fit">
                  No new alerts!
                </PopoverContent>
              </Popover>
            )}

            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-border"
              aria-hidden="true"
            />
            <Link
              to={Routes.HelpdeskNewTicket}
              className="flex p-2 -mx-2 text-sm font-semibold leading-6 rounded-md group gap-x-3 text-muted hover:bg-indigo-700 hover:text-white"
            >
              <ChatBubbleLeftIcon
                className="w-6 h-6 shrink-0 text-muted group-hover:text-white"
                aria-hidden="true"
              />
              Need help?
            </Link>
            {user && userNavigation && (
              <ProfileDropdown user={user} userNavigation={userNavigation} />
            )}
            {!user && (
              <div className="hidden gap-2 lg:flex">
                <Link to={Routes.Login}>
                  <Button className="w-full">Login</Button>
                </Link>
                <Link to={Routes.SignUp}>
                  <Button className="w-full" variant="secondary">
                    Sign Up
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      <main>
        <Outlet />
      </main>
    </div>
  );
}

function ProfileDropdown({
  user,
  userNavigation,
}: {
  user: UserSession;
  userNavigation: UserNavigationItem[];
}) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">Open user menu</span>
        <Avatar className="w-8 h-8">
          <AvatarFallback>{`${user.firstName[0]}${user.lastName[0]}`}</AvatarFallback>
        </Avatar>
        <span className="hidden lg:flex lg:items-center">
          <span
            className="ml-4 text-sm font-semibold leading-6 text-muted"
            aria-hidden="true"
          >
            {`${user.firstName} ${user.lastName}`}
          </span>
          <ChevronDownIcon
            className="w-5 h-5 ml-2 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-background py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <Link
                  className="block px-3 py-1 text-sm leading-6 text-muted"
                  to={item.to}
                >
                  {item.name}
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function displayPathName(pathname: string) {
  if (pathname.includes('dcim')) {
    return 'Data Center Infrastructure Management';
  }

  if (pathname.includes('marketplace')) {
    return 'Marketplace';
  }

  return capitalizeFirstLetter(pathname.split('/')[1]);
}
