import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { Logo } from '~/assets/Logo';
import { Routes } from '~/constants/routes';

export type NavigationItem = {
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string | undefined;
      titleId?: string | undefined;
    } & React.RefAttributes<SVGSVGElement>
  >;
  name: string;
  to?: Routes;
  children?: {
    name: string;
    to: Routes;
  }[];
  labelClassName?: string;
};

export function MobileNavigationBar({
  pathname,
  navigation,
  sidebarOpen,
  setSidebarOpen
}: {
  pathname: string;
  navigation: NavigationItem[];
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50 lg:hidden' onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter='transition-opacity ease-linear duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-900/80' />
        </Transition.Child>

        <div className='fixed inset-0 flex'>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                  <button
                    type='button'
                    className='-m-2.5 p-2.5'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4'>
                <div className='flex h-16 shrink-0 items-center'>
                  <Logo className='h-8 w-auto' />
                </div>
                <nav className='flex flex-1 flex-col'>
                  <ul className='flex flex-1 flex-col gap-y-7'>
                    <li>
                      <ul className='-mx-2 space-y-1'>
                        {navigation.map((item) => {
                          return (
                            <li key={item.name}>
                              {!item.children ? (
                                <Link
                                  to={item.to!}
                                  className={clsx(
                                    'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-muted',
                                    pathname.includes(item.to!) && 'text-white',
                                    item.labelClassName
                                  )}
                                >
                                  <item.icon className='h-6 w-6 shrink-0' aria-hidden='true' />
                                  {item.name}
                                </Link>
                              ) : (
                                <Disclosure
                                  as='div'
                                  defaultOpen={item.children.some((child) =>
                                    pathname.includes(child.to)
                                  )}
                                >
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={clsx(
                                          'flex w-full items-center gap-x-3 rounded-md  p-2 text-left text-sm font-semibold leading-6 text-muted',
                                          item.children!.some((child) =>
                                            pathname.includes(child.to)
                                          ) && 'text-white'
                                        )}
                                      >
                                        <item.icon
                                          className='h-6 w-6 shrink-0'
                                          aria-hidden='true'
                                        />

                                        {item.name}
                                        <ChevronRightIcon
                                          className={clsx(
                                            open ? 'rotate-90' : '',
                                            'ml-auto h-5 w-5 shrink-0'
                                          )}
                                          aria-hidden='true'
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel as='ul' className='mt-1 px-2'>
                                        {item.children?.map((subItem) => (
                                          <li key={subItem.name}>
                                            <Link
                                              className={clsx(
                                                'block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-muted hover:text-foreground',
                                                pathname.includes(subItem.to) &&
                                                  'font-bold text-white'
                                              )}
                                              to={subItem.to}
                                            >
                                              {subItem.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
